<template>
  <v-app-bar fixed centered class="navbar" height="80px">
    <v-avatar size="80" style="padding: 10px">
      <img src="../../assets/ib_logo_schwarz_01_kleiner (1).png" style="cursor: pointer" @click="$router.push({ name: 'Home', hash: '#home' })">
    </v-avatar>
    <div class="navbar-container" v-if="$vuetify.breakpoint.mdAndUp">
      <router-link :to="{ name: 'Home', hash: '#home' }" class="link">
        HOME
      </router-link>
      <router-link :to="{ name: 'Home', hash: '#media' }" class="link">
        MEDIA
      </router-link>
      <router-link :to="{ name: 'Home', hash: '#instagram' }" class="link">
        ON INSTAGRAM
      </router-link>
      <router-link :to="{ name: 'Home', hash: '#newsletter' }" class="link">
        NEWSLETTER
      </router-link>
      <router-link :to="{ name: 'Home', hash: '#tourdates' }" class="link">
        TOUR DATES
      </router-link>
      <router-link :to="{ name: 'Home' }" class="link" v-if="false">
        LYRICS
      </router-link>
      <router-link :to="{ name: 'Home', hash: '#contact' }" class="link">
        KONTAKT
      </router-link>
      <a @click="navigateTo('https://shop.indecent-behavior.com')" class="link">
        SHOP
      </a>
    </div>
    <div v-else style="width: 100%">
      <div style="width: 100%; text-align: right">
        <v-app-bar-nav-icon @click="$store.commit('showDrawer')" style="color: white"></v-app-bar-nav-icon>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
  export default {
    data: () => ({
      group: null,
    }),
    methods: {
      navigateTo (target) {
        window.open(target, '_blank')
      }
    }
  }
</script>

<style scoped>
.navbar {
  width: 100%;
  background-color: #00000080 !important
}

.navbar-container {
  font-weight: 700;
  padding: 20px 0px;
  margin: auto;
}

.link {
  padding: 10px;
  text-decoration: none;
  color: white;
  font-size: 14px;
  cursor: pointer
}

.link:hover {
  color: #ff5454;
}
</style>

<template>
  <div class="home">
    <div id="home">
      <ib-presenter></ib-presenter>
    </div>
    <div id="media">
      <ib-media></ib-media>
    </div>
    <div id="instagram">
      <ib-instagram></ib-instagram>
    </div>
    <div id="newsletter">
      <ib-newsletter></ib-newsletter>
    </div>
    <div id="tourdates">
      <ib-tour-dates></ib-tour-dates>
    </div>
    <div id="contact">
      <ib-contact></ib-contact>
    </div>
  </div>
</template>

<script>
import Presenter from '@/components/home/Presenter.vue'
import Media from '@/components/home/Media.vue'
import Instagram from '@/components/home/Instagram.vue'
import Newsletter from '@/components/home/Newsletter.vue'
import TourDates from '@/components/home/TourDates.vue'
import Contact from '@/components/home/Contact.vue'

export default {
  name: 'Home',
  components: {
    ibPresenter: Presenter,
    ibMedia: Media,
    ibInstagram: Instagram,
    ibNewsletter: Newsletter,
    ibTourDates: TourDates,
    ibContact: Contact
  }
}
</script>

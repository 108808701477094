import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import('../views/Imprint.vue')
  },
  {
    path: '/revocation',
    name: 'Revocation',
    component: () => import('../views/Revocation.vue')
  },
  {
    path: '/agb',
    name: 'AGB',
    component: () => import('../views/AGB.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/Seite-Wurde-Nicht-Gefunden',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/Shop.vue')
  },
  {
    path: "/tickets",
    beforeEnter() {
      window.location.href = "https://tourlink.to/therapy-in-melody";
    }
  },
  {
    path: '*',
    redirect: '/Seite-Wurde-Nicht-Gefunden'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

export default router

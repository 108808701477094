<template>
  <div style="padding: 50px 2%">
    <!-- Begin Mailchimp Signup Form -->
    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css" rel="stylesheet" type="text/css">
    <div id="mc_embed_signup" style="margin: auto" :style="{ 'max-width': !$vuetify.breakpoint.xs ? '800px' : '' }">
      <form action="https://indecent-behavior.us1.list-manage.com/subscribe/post?u=cc724d2e4994d54297a3099ed&amp;id=95c51df374" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
          <h2>🤘 Keine Info mehr verpassen mit dem Indecent Behavior Newsletter 🤘</h2>
          <p>
            Trag dich ein und erhalte alle Infos, Neuigkeiten, Tourtermine und vieles mehr. So verpasst du nichts mehr! ❤️
          </p>
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email Address </label>
            <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
          </div>
          <div id="mce-responses" class="clear foot">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_cc724d2e4994d54297a3099ed_95c51df374" tabindex="-1" value=""></div>
              <div class="optionalParent">
                <div class="clear foot">
                  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" style="background-color: green">
                  <p class="brandingLogo"><a href="http://eepurl.com/hSXWoT" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"></a></p>
                </div>
              </div>
            </div>
      </form>
    </div>
    <!--End mc_embed_signup-->
  </div>
</template>

<script>
  export default {
    data () {
      return {}
    }
  }
</script>

<style scoped>
#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
        We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>

<template>
  <div style="padding: 50px 6%" class="instagram">
    <h1 style="color: white; margin-bottom: 50px">On Instagram</h1>
    <!-- Place <div> tag where you want the feed to appear -->
    <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
    <v-btn x-large @click="navigate('https://instagram.com/indecentbehaviorofficial')" style="margin-top: 50px" :style="{ 'font-size': $vuetify.breakpoint.xs ? '12px' : '' }">
      <v-icon style="margin-right: 10px">mdi-instagram</v-icon> Instagram Feed auschecken
    </v-btn>
  </div>
</template>

<script>
  /* curator-feed-default-feed-layout */
  (function(){
  var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
  i.src="https://cdn.curator.io/published/e8978e48-36a7-4d9d-8022-470f6b3c2657.js";
  e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
  })();
</script>

<script>
export default {
  methods: {
    navigate (target) {
      window.open(target, '_blank')
    }
  }
}
</script>

<style scoped>
.instagram {
  background-image: url('~@/assets/Blatt_1080p.jpg');
  background-size: cover
}
</style>

<template>
  <div id="app">
    <ib-navbar @drawer="drawer = true">
    </ib-navbar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      dark
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          active-class="text--accent-4"
        >
          <v-list-item>
            <v-list-item-title class="link" @click="navigate({ name: 'Home', hash: '#home' })">Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="link" @click="navigate({ name: 'Home', hash: '#media' })">Media</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="link" @click="navigate({ name: 'Home', hash: '#instagram' })">On Instagram</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="link" @click="navigate({ name: 'Home', hash: '#newsletter' })">Newsletter</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="link" @click="navigate({ name: 'Home', hash: '#tourdates' })">Tour Dates</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="false">
            <v-list-item-title class="link" @click="navigate({ name: 'Home', hash: '#home' })">Lyrics</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="link" @click="navigate('https://shop.indecent-behavior.com')">Shop</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <router-view/>
    <ib-footer></ib-footer>
  </div>
</template>

<script>
import Navbar from '@/components/global/Navbar.vue'
import Footer from '@/components/global/Footer.vue'

export default {
  computed: {
    drawer: {
      get() {
        return this.$store.getters.drawer; 
      },
      set(state) {
        if (!state) {
          this.closeDrawer()
        }
        else {
          this.openDrawer()
        }
      },
    }
  },
  components: {
    ibNavbar: Navbar,
    ibFooter: Footer
  },
  methods: {
    navigate (target) {
      if (target.name) {
        this.$router.push(target)
      } else {
        window.open(target)
      }
    },
    openDrawer () {
      this.$store.commit('showDrawer')
    },
    closeDrawer () {
      this.$store.commit('disableDrawer')
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: "Droid Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #ff5454 !important;
    }
  }
}

.link {
  padding: 10px;
  text-decoration: none;
  color: white;
  font-size: 14px;
}

html {
  scroll-behavior: smooth;
}

p {
  margin-bottom: 18px
}

h1 {
  margin-top: 26px;
  margin-bottom: 26px
}

h2 {
  margin-top: 22px;
  margin-bottom: 22px
}
</style>
